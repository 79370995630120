<template>
	<div>
		<footer>
			<div class="row">
				<div class="columns column3">
					<div class="logo" />
				</div>
				<div class="columns column3">
					<h3>{{ $t('contact') }}</h3>
					<p>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
							<span>{{ defaults[locale].website.phone }}</span> </a
						><br />
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
				</div>
				<div class="columns column3">
					<h3>{{ defaults[locale].website.hotelName }}</h3>
					<p>
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span
						><br />
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span
						><br />
						<span>{{ defaults[locale].website.country }}</span
						><br />
					</p>
				</div>
				<div class="columns column3">
					<h3>{{ $t('footerNavigate') }}</h3>
					<p class="footer-nav">
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }} <br />
						</nuxt-link>
					</p>
				</div>
			</div>
		</footer>
		<footer class="author">
			<div class="row">
				<div class="columns column12 align-center">
					<p class="align-left">
						<a :href="$t('siteByUrl')" target="_blank" rel="noopener noreferrer"> Hotel Website Design </a>
						by:
						<a :href="$t('siteByUrl')" target="_blank">
							<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="Site by" />
							Porter
						</a>
					</p>
					<p class="align-right">
						<span v-for="(item, idx) in bottomfooter[locale]" :key="item.filename">
							<nuxt-link :to="item.filename">
								{{ item.header }}
							</nuxt-link>
							<span v-if="idx < bottomfooter[locale].length - 1">|</span>
						</span>
						<span v-if="defaults[locale].cookieConsent.published">
							<span> | </span>
							<a href="#" @click.prevent="openConsent">{{ $t('consentOpen') }}</a>
						</span>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu, bottomfooter } = footer.value;
const { locale } = useI18n();

const { defaults, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
footer {
	background: $footer-background-color;
	padding: 75px 0 50px;
	font-size: 15px;

	.logo {
		background: url('~/assets/images/logo-corona-hotel-black-full-B.png') no-repeat center center;
		background-size: 184px;
		width: 184px;
		height: 72px;
	}
}

footer a {
	color: $footer-link-color;

	svg {
		margin: 0 10px 0 0;
	}

	&:hover {
		color: $cta-color;
	}
}

footer.author {
	padding: 20px 0;
	font-size: 12px;
	background: #d4c6b6;
	border: none;

	p {
		margin: 0;
	}
}

.author .column12 {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.author img {
	max-width: 20px;
	margin: 0 1px 0 2px;
}

.footer-logo {
	max-width: 180px;
	margin: 0 0 30px;
}

@media (max-width: 920px) {
	footer .columns {
		margin: 20px auto;
	}

	.author .columns {
		margin: 0;
		flex-flow: wrap column-reverse;
	}

	footer.author p {
		width: 100%;
		margin: 15px 0 5px;
		text-align: center;
	}
}

@media (max-width: 680px) {
	footer {
		text-align: center;

		.logo {
			margin: 0 auto;
		}
	}
}
</style>
